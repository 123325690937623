<template >
  <router-view />
</template>
<script>
export default {
  name: 'signal',
  created() {
    const route = this.$route.matched.find(item => item.name === 'signal')
    this.$store.dispatch('addCachedView', route)
  }
}
</script>
